import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useLayoutEffect
} from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useLocation } from "@reach/router";

import Grid from "@material-ui/core/Grid";
import "../../assets/css/menu.css";
import "../../assets/css/minified.css";

import CategoryItems from "./components/CategoryItems/BDCategoryItems";
import Facets from "./components/AC-Facets/BDFacets";
import Pagination from "./components/BDPagination";
import PaginationRegular from "../AC-Pagination/BDPagination";

import NumberOfItems from "./components/NumberOfItems";
import Loading from "../AC-Loading/Loading.jsx";
import FacetBreadcrumb from "../AC-Breadcrumb/BDFacetBreadcrumb.jsx";
import SortBy from "./components/SortBy";
import Facets_Mobile from "../AC-Facets/Facets_Mobile.jsx";

import classes from "./styles/BDCategory.module.css";

import {
  dispatchScroolPage,
  nextPage,
  nextPageGatsby,
  nextPageScrool
} from "../../redux/actions/paginationActions.js";
import { I18nContext } from "../../i18n";

import CategoryHeader from "./components/CategoryHeader/BDCategoryHeader";
import {
  gatsbyFetchCategory,
  backToCategoryNormalize,
  fetchCategoryFromDirectUrl
} from "../../redux/actions/categoryActions.js";
import StoreCategoryHeader from "../AC-Stores/components/StoreCategoryHeaderStore";
import { setCategoryNavCatAction } from "../../redux/actions/menuActions";
import NoItems from "./components/NoItems/NoItems";
import { renderPlaceholderCategoryItems } from "../AC-CategoryPage/components/CategoryItems/CategoryItems";
import { handleScroolPosition } from "../../redux/actions/mainActions";
import SubCategories from "./components/SubCategories/SubCategories";

function BDCategory({ data, pageContext, navCategory, supplier }) {
  const dispatch = useDispatch();

  const backButtonState = useSelector(
    state => state.categoryReducer.backButton,
    shallowEqual
  );
  console.info("borop category data2", data);

  const showDynamicState = useSelector(
    state => state.categoryReducer.showDynamic,
    shallowEqual
  );

  const initialLoadState = useSelector(
    state => state.mainReducer.initialLoad,
    shallowEqual
  );

  const DISABLE_FACETS = true;

  const [categoryItems, setCategoryItems] = useState(
    pageContext.currentPage === 1
      ? data.itemsFirstPage
      : data.otherPages[`_${pageContext.currentPage}`]
  );

  useEffect(() => {
    if (typeof window !== undefined) window.scrollTo(0, 0);

    dispatch(setCategoryNavCatAction(navCategory));
  }, []);

  useEffect(() => {
    if (!initialLoadState) {
      if (!backButtonState) {
        dispatch(gatsbyFetchCategory(data, pageContext, data.url));
      } else {
        dispatch(backToCategoryNormalize());
      }

      if (showDynamicState && backButtonState) {
        dispatch(fetchCategoryFromDirectUrl());
      }
    }
  }, [initialLoadState]);

  const { translate } = useContext(I18nContext);
  const [filterButtonClicked, setFilterButtonClicked] = useState(false);

  const location = useLocation();

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const loadingBottom = useSelector(
    state => state.categoryReducer.loadingBottom,
    shallowEqual
  );

  const pagesState = useSelector(
    state => state.categoryReducer.pages,
    shallowEqual
  );

  const noItemFoundState = useSelector(
    state => state.categoryReducer.noItemFound,
    shallowEqual
  );

  const loading = useSelector(
    state => state.categoryReducer.loading,
    shallowEqual
  );

  const currentPageState = useSelector(
    state => state.categoryReducer.currentPage,
    shallowEqual
  );

  const scroolPageState = useSelector(
    state => state.categoryReducer.scroolPage,
    shallowEqual
  );

  const cidState = useSelector(
    state => state.categoryReducer.cidN,
    shallowEqual
  );

  const breadState = useSelector(
    state => state.facetReducer.bread,
    shallowEqual
  );
  const urlFilterParamsState = useSelector(
    state => state.facetReducer.urlFilterParams,
    shallowEqual
  );

  const clientSideCategoryItemState = useSelector(
    state => state.categoryReducer.categoryItems,
    shallowEqual
  );

  const numberOfItemState = useSelector(
    state => state.categoryReducer.numberOfItems,
    shallowEqual
  );

  const numberOfItemsGridSizes = {
    xs: numberOfItemState === 0 ? 12 : 6,
    sm: numberOfItemState === 0 ? 12 : 6,
    md: numberOfItemState === 0 ? 12 : 3
  };
  const itemsFetchedState = useSelector(
    state => state.categoryReducer.itemsFetched,
    shallowEqual
  );

  const facetsState = useSelector(
    state => state.facetReducer.facets,
    shallowEqual
  );

  const scState = useSelector(state => state.categoryReducer.sc, shallowEqual);
  const scPosState = useSelector(
    state => state.categoryReducer.scPos,
    shallowEqual
  );

  useEffect(() => {
    if (scState && scPosState > 0) {
      setTimeout(() => {
        window.scrollTo({
          top: Math.trunc(scPosState),
          left: 0
        });
        dispatch(nextPageScrool());
      }, 0);
    }
  }, [scState, scPosState]);

  const handleNextPage = (e, page) => {
    let position = 0;
    if (e !== "") {
      position = window.pageYOffset;
    }
    if (
      scroolPageState < pagesState.length &&
      page !== 0 &&
      (breadState.length > 0 || urlFilterParamsState !== "")
    ) {
      dispatch(dispatchScroolPage(page));
      dispatch(nextPage(page, cidState, true));
    } else if (
      scroolPageState < pagesState.length &&
      page !== 0 &&
      breadState.length == 0
    ) {
      dispatch(dispatchScroolPage(page));

      dispatch(nextPageGatsby(data.otherPages[`_${page}`], position));
    } else {
      console.info("End of the pagea");
    }
  };

  const handleFilterButtonClicked = () => {
    document.getElementById("___gatsby").setAttribute("aria-hidden", true);
    setFilterButtonClicked(true);
  };

  const handleFacetContentCloseIconClicked = () => {
    document.getElementById("___gatsby").setAttribute("aria-hidden", false);
    setFilterButtonClicked(false);
  };

  /*  useLayoutEffect(() => {
    if (!location.pathname.includes("stores")) {
      const { left, right } = mapCategoryUrls(location.pathname);
      if (typeof window !== undefined) {
        let start = 0;
        let end = 0;
        let startY = 0;
        let endY = 0;
        let touchTime = 0;
        let diff = 0;

        const touchStartEvent = e => {
          if (e.changedTouches[0]) {
            start = e.changedTouches[0].screenX;
            startY = e.changedTouches[0].screenY;
            touchTime = new Date();
          }
        };

        const touchEndEvent = e => {
          if (e.changedTouches[0]) {
            end = e.changedTouches[0].screenX;
            endY = e.changedTouches[0].screenY;
            diff = new Date() - touchTime;

            handleSwiper();
          }
        };

        const handleSwiper = () => {
          if (Math.abs(end - start) > Math.abs(endY - startY) && diff > 100) {
            if (end < start) {
              navigate(`/${left}`, { state: { directionCat: "left" } });
            } else if (end > start) {
              navigate(`/${right}`, { state: { directionCat: "right" } });
            }
          }
        };

        window.addEventListener("touchstart", touchStartEvent);

        window.addEventListener("touchend", touchEndEvent);

        return () => {
          window.removeEventListener("touchstart", touchStartEvent);
          window.removeEventListener("touchend", touchEndEvent);
        };
      }
    }
  }, []);
 */

  console.info("facetsState", facetsState, !facetsState);
  const renderFacets = () => {
    if (isMobileState) {
      return (
        <React.Fragment>
          <button
            className={classes.mobileFilterBtn}
            onClick={() => handleFilterButtonClicked()}
          >
            {translate("mobile_facets.filterButtonText")}
            <i className="material-icons">filter_list</i>
          </button>
          <Facets_Mobile
            filterButtonClicked={filterButtonClicked}
            handleFacetContentCloseIconClicked={
              handleFacetContentCloseIconClicked
            }
          />
        </React.Fragment>
      );
    } else return <Facets data={data} />;
  };

  const myRef = useRef(null);

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      const scroolState = () => {
        let pageoffset = 0;
        if (typeof window !== "undefined") {
          pageoffset = window.pageYOffset;
        }
        dispatch(handleScroolPosition(pageoffset));
      };

      if (!loadingBottom && scroolPageState - currentPageState <= 2) {
        let handleOnScroll = () => {
          scroolState();
          if (!loading && myRef.current) {
            let scrollTop =
              (document.documentElement &&
                document.documentElement.scrollTop) ||
              document.body.scrollTop;
            let scrollHeight = myRef.current.offsetTop;
            let clientHeight =
              document.documentElement.clientHeight || window.innerHeight;
            let scrolledToBottom =
              Math.ceil(scrollTop + clientHeight) >= scrollHeight;

            if (scrolledToBottom) {
              handleNextPage("", scroolPageState + 1);
            }
          }
        };

        window.addEventListener("scroll", handleOnScroll);

        // returned function will be called on component unmount
        return () => {
          window.removeEventListener("scroll", handleOnScroll);
        };
      } else {
        if (typeof window !== "undefined") {
          window.addEventListener("scroll", scroolState);
        }
        // returned function will be called on component unmount
        return () => {
          if (typeof window !== "undefined") {
            window.removeEventListener("scroll", scroolState);
          }
        };
      }
    }
  });

  const renderHeader = () => {
    if (location.pathname.includes("stores")) {
      return (
        <StoreCategoryHeader
          data={data}
          storeSellerData={supplier.length > 0 ? supplier[0] : undefined}
          pickupLocData={
            supplier.length > 0 ? supplier[0].pickup_locations : undefined
          }
          supplierShippingInfo={
            supplier.length > 0 ? supplier[0].shipping_information : undefined
          }
          sellerHaveNoProducts={categoryItems && categoryItems.length === 0}
        />
      );
    } else {
      return <CategoryHeader data={data} />;
    }
  };

  if (
    noItemFoundState &&
    cidState !== "search" &&
    !location.pathname.includes("stores")
  ) {
    return (
      <div id="bd">
        <div className="main">
          {renderHeader()}
          <div className="row">
            <div className="category-container">
              <NoItems />
              <div ref={myRef} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const renderCategoryContent = () => {
    if (categoryItems && categoryItems.length > 0) {
      return (
        <div className="row">
          <div className="category-container">
            {/*   <DistanceFilter /> */}

            <Grid container justify="space-between" alignItems="center">
              <Grid mb="1rem" item xs={12} sm={12} md={12} lg={12}>
                <SubCategories childCats={data.childCats} />
              </Grid>
              {DISABLE_FACETS === false && facetsState && (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={4}
                  lg={3}
                  className="grid-facets-container"
                >
                  {/*  <SubCategoryMenu /> */}

                  {renderFacets()}
                </Grid>
              )}
              <Grid
                item
                xs={6}
                sm={8}
                md={8}
                lg={9}
                className={`grid-category-items-container${
                  (facetsState && facetsState.length == 0) ||
                  !facetsState ||
                  DISABLE_FACETS
                    ? " no-facets"
                    : ""
                }`}
              >
                <Grid container justify="space-around" alignItems="center">
                  <Grid item xs={12} style={{ margin: "0 1%" }}>
                    <FacetBreadcrumb data={data} />
                  </Grid>
                  <Grid
                    className="pagination-container"
                    item
                    xs={12}
                    style={{
                      backgroundColor: "#fff",
                      padding: "10px",
                      margin: "0 1%"
                    }}
                  >
                    <Grid container justify="flex-end" alignItems="center">
                      {/* <Grid item {...numberOfItemsGridSizes}>
                        <NumberOfItems data={data} />
                      </Grid>
                      <Grid item xs={6} sm={6} md={3}>
                        <SortBy />
                      </Grid> */}
                      <Grid item xs={12} sm={12} md={6}>
                        {showDynamicState &&
                        (breadState.length > 0 ||
                          urlFilterParamsState !== "") ? (
                          <PaginationRegular data={data} />
                        ) : (
                          <Pagination data={data} pageContext={pageContext} />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <CategoryItems
                      data={data}
                      pageContext={pageContext}
                      supplier={supplier}
                    />
                    {loadingBottom ? <Loading /> : null}
                    <div ref={myRef} />
                    {!loadingBottom &&
                    scroolPageState - currentPageState > 2 &&
                    pagesState.length > scroolPageState ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <button
                          style={{
                            backgroundColor: "#222e50",
                            borderRadius: "0",
                            boxShadow: "none",
                            fontWeight: "700",
                            textTransform: "uppercase",
                            letterSpacing: "2px",
                            lineHeight: "1.42857143",
                            padding: "6px 15px",
                            fontSize: "1em",
                            color: "white"
                          }}
                          onClick={e => handleNextPage(e, scroolPageState + 1)}
                        >
                          More Items
                        </button>
                      </div>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    } else return null;
  };

  if ((loading && showDynamicState) || initialLoadState) {
    return (
      <div id="bd">
        <div className="main">
          {renderHeader()}

          <Loading className="loadingCategory" />

          <div className="row">
            <div className="category-container">
              {/*    <DistanceFilter loading={loading} /> */}
              <Grid container justify="space-between" alignItems="center">
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={4}
                  lg={3}
                  className="grid-facets-container"
                ></Grid>
                <Grid
                  item
                  xs={6}
                  sm={8}
                  md={8}
                  lg={9}
                  className={`grid-category-items-container${
                    facetsState && facetsState.length == 0 ? " no-facets" : ""
                  }`}
                >
                  <Grid container justify="space-around" alignItems="center">
                    <Grid item xs={12} style={{ margin: "0 1%" }}></Grid>
                    <Grid
                      className="pagination-container"
                      item
                      xs={12}
                      style={{
                        backgroundColor: "#f5f5f5",
                        padding: "10px",
                        margin: "0 1%"
                      }}
                    >
                      <Grid
                        container
                        justify="space-around"
                        alignItems="center"
                      >
                        <Grid item {...numberOfItemsGridSizes}></Grid>
                        <Grid item xs={6} sm={6} md={3}></Grid>
                        <Grid item xs={12} sm={12} md={6}></Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      {renderPlaceholderCategoryItems()}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      // Regular Layout

      <div id="bd">
        <div className="main">
          {renderHeader()}
          {categoryItems &&
            categoryItems.length === 0 &&
            data.childCats.length > 0 && (
              <div className="row">
                <div className="category-container">
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <SubCategories childCats={data.childCats} />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          {renderCategoryContent()}
        </div>
      </div>
    );
  }
}

export default BDCategory;
