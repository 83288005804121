import { Link } from "gatsby";
import React from "react";
import { PROJECT_LINK } from "../../../../../preBuildScripts/misc/links";

import style from "./style/style.module.css";

const SubCategories = ({ childCats }) => {
  if (childCats.length > 0) {
    return (
      <div className={style.subCatDiv}>
        {childCats.map(cat => {
          return (
            <Link className={style.subCatItem} to={"/" + cat.URL}>
              <div className={style.divSub}>
                <div className={style.catImg}>
                  <img
                    src={PROJECT_LINK + "/store" + cat.image}
                    alt={cat.name + " Image"}
                  />
                </div>

                <div className={style.catDesc}>
                  <div className={style.catTitle}>
                    <span dangerouslySetInnerHTML={{ __html: cat.name }}></span>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};

export default SubCategories;
