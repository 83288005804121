import React from "react";
import { graphql } from "gatsby";
import Layout from "../other/layout";
import CategoryPage from "../components/OFB-Category/Category";
import CategorySellersPage from "../components/AC-Stores/StoresGatsby";

import SEO from "../other/seo";
import { SOLE_PROJECT_LINK, STORE_LINK } from "../project-config";

export const query = graphql`
  query MyQuery2($url: [String]) {
    allAvettiMenu {
      edges {
        node {
          name
          childs {
            name
            cid
            URL
            description
            image
            position
            thumbnail
            vid
          }
        }
      }
    }
    allAvettiCategory(filter: { url: { in: $url } }) {
      nodes {
        url
        name

        image
        itemsCount
        numberOfPages
        itemsFirstPage {
          id
          title
          code
          desc
          currency_sign
          image
          itemLargeImage
          price {
            value {
              integer
              decimal
            }
          }
          prices {
            packagePrices {
              price
              quantity
            }
            itemcode
            priceLabel
            price_1
          }
          url
          properties {
            UPC
            Size
            Stock
            logoW
            logoX
            logoY
            Other_Versions
            Quantity
            Compatibility
            Background_s_
            Available_Layouts
            secondSigLineY
            secondSigLineX
            secondSigLineW
            secondSigAdd
            sampleItemCode
            layoutClass
            itemCSSFile
            cheque
            Sys_Private_Sale_Starts
            Sys_Private_Sale_Item
            Sys_Private_Sale_Ends
            Sys_Package_Price_Enabled
            Sys_Item_Logo
            Consecutive_Numbering
            Fabric
            Compatible_Envelopes
          }
        }
        cid
        id
        facets {
          Price {
            count
            removeText
            text
            value
          }
          Reviews {
            count
            removeText
            text
            value
          }
        }
        otherPages {
          _2 {
            code
            count
            properties {
              Fabric
              Other_Versions
              Quantity
              Stock
              Size
              Available_Layouts
              Background_s_
              Compatibility
              Compatible_Envelopes
              Consecutive_Numbering
              Sys_Item_Logo
              Sys_Package_Price_Enabled
              Sys_Private_Sale_Ends
              Sys_Private_Sale_Item
              Sys_Private_Sale_Starts
              UPC
              itemCSSFile
              layoutClass
              logoW
              logoX
              logoY
              sampleItemCode
              secondSigAdd
              secondSigLineW
              secondSigLineX
              secondSigLineY
            }
            cat_featured
            desc
            currency_sign
            id
            image
            itemLargeImage
            itemSellerName
            itemSellerLocation
            price {
              value {
                integer
                decimal
              }
            }
            prices {
              packagePrices {
                price
                quantity
              }
              itemcode
              priceLabel
              price_1
            }
            url
            title
          }
          # _3 {
          #   code
          #   count
          #   properties {
          #     Sys_Item_Logo
          #     Sys_Package_Price_Enabled
          #     Sys_Private_Sale_Ends
          #     Sys_Private_Sale_Item
          #     Sys_Private_Sale_Starts
          #     UPC
          #   }
          #   cat_featured
          #   desc
          #   currency_sign
          #   id
          #   image
          #   itemLargeImage
          #   itemSellerName
          #   itemSellerLocation
          #   price {
          #     value {
          #       integer
          #       decimal
          #     }
          #   }
          #   prices {
          #     packagePrices {
          #       price
          #       quantity
          #     }
          #     itemcode
          #     priceLabel
          #     price_1
          #   }
          #   url
          #   title
          # }
          # _4 {
          #   code
          #   count
          #   properties {
          #     Sys_Item_Logo
          #     Sys_Package_Price_Enabled
          #     Sys_Private_Sale_Ends
          #     Sys_Private_Sale_Item
          #     Sys_Private_Sale_Starts
          #     UPC
          #   }
          #   cat_featured
          #   desc
          #   currency_sign
          #   id
          #   image
          #   itemLargeImage
          #   itemSellerName
          #   itemSellerLocation
          #   price {
          #     value {
          #       integer
          #       decimal
          #     }
          #   }
          #   prices {
          #     packagePrices {
          #       price
          #       quantity
          #     }
          #     itemcode
          #     priceLabel
          #     price_1
          #   }
          #   url
          #   title
          # }
          # _5 {
          #   code
          #   count
          #   properties {
          #     Sys_Item_Logo
          #     Sys_Package_Price_Enabled
          #     Sys_Private_Sale_Ends
          #     Sys_Private_Sale_Item
          #     Sys_Private_Sale_Starts
          #     UPC
          #   }
          #   cat_featured
          #   desc
          #   currency_sign
          #   id
          #   image
          #   itemLargeImage
          #   itemSellerName
          #   itemSellerLocation
          #   price {
          #     value {
          #       integer
          #       decimal
          #     }
          #   }
          #   prices {
          #     packagePrices {
          #       price
          #       quantity
          #     }
          #     itemcode
          #     priceLabel
          #     price_1
          #   }
          #   url
          #   title
          # }
          # _6 {
          #   code
          #   count
          #   properties {
          #     Sys_Item_Logo
          #     Sys_Package_Price_Enabled
          #     Sys_Private_Sale_Ends
          #     Sys_Private_Sale_Item
          #     Sys_Private_Sale_Starts
          #     UPC
          #   }
          #   cat_featured
          #   desc
          #   currency_sign
          #   id
          #   image
          #   itemLargeImage
          #   itemSellerName
          #   itemSellerLocation
          #   price {
          #     value {
          #       integer
          #       decimal
          #     }
          #   }
          #   prices {
          #     packagePrices {
          #       price
          #       quantity
          #     }
          #     itemcode
          #     priceLabel
          #     price_1
          #   }
          #   url
          #   title
          # }
          # _7 {
          #   code
          #   count
          #   properties {
          #     Sys_Item_Logo
          #     Sys_Package_Price_Enabled
          #     Sys_Private_Sale_Ends
          #     Sys_Private_Sale_Item
          #     Sys_Private_Sale_Starts
          #     UPC
          #   }
          #   cat_featured
          #   desc
          #   currency_sign
          #   id
          #   image
          #   itemLargeImage
          #   itemSellerName
          #   itemSellerLocation
          #   price {
          #     value {
          #       integer
          #       decimal
          #     }
          #   }
          #   prices {
          #     packagePrices {
          #       price
          #       quantity
          #     }
          #     itemcode
          #     priceLabel
          #     price_1
          #   }
          #   url
          #   title
          # }
          # _8 {
          #   code
          #   count
          #   properties {
          #     Sys_Item_Logo
          #     Sys_Package_Price_Enabled
          #     Sys_Private_Sale_Ends
          #     Sys_Private_Sale_Item
          #     Sys_Private_Sale_Starts
          #     UPC
          #   }
          #   cat_featured
          #   desc
          #   currency_sign
          #   id
          #   image
          #   itemLargeImage
          #   itemSellerName
          #   itemSellerLocation
          #   price {
          #     value {
          #       integer
          #       decimal
          #     }
          #   }
          #   prices {
          #     packagePrices {
          #       price
          #       quantity
          #     }
          #     itemcode
          #     priceLabel
          #     price_1
          #   }
          #   url
          #   title
          # }
          # _9 {
          #   code
          #   count
          #   properties {
          #     Sys_Item_Logo
          #     Sys_Package_Price_Enabled
          #     Sys_Private_Sale_Ends
          #     Sys_Private_Sale_Item
          #     Sys_Private_Sale_Starts
          #     UPC
          #   }
          #   cat_featured
          #   desc
          #   currency_sign
          #   id
          #   image
          #   itemLargeImage
          #   itemSellerName
          #   itemSellerLocation
          #   price {
          #     value {
          #       integer
          #       decimal
          #     }
          #   }
          #   prices {
          #     packagePrices {
          #       price
          #       quantity
          #     }
          #     itemcode
          #     priceLabel
          #     price_1
          #   }
          #   url
          #   title
          # }
        }
        childCats {
          cid
          name
          position
          image
          URL
        }
      }
    }
  }
`;
const Category = ({ data, pageContext }) => {
  const name = data.allAvettiCategory.nodes[0].name;
  const meta = data.allAvettiCategory.nodes[0].meta;
  const image = data.allAvettiCategory.nodes[0].image;
  // console.info("DATA HERE", data.allAvettiCategory.nodes[0]);
  const imageUrl = `${STORE_LINK}/${image}`;
  const navCategory = data.allAvettiMenu.edges[0].node.childs.filter(
    chil =>
      chil.URL.replace("shop/", "") === data.allAvettiCategory.nodes[0].url
  )[0];

  return (
    <Layout>
      {
        <SEO
          description={name}
          lang={"en"}
          title={name}
          pathname={data.allAvettiCategory.nodes[0].url}
          meta={[
            {
              name: `og:image`,
              content: imageUrl
            },
            {
              name: `og:image:secure_url`,
              content: imageUrl
            },
            {
              name: `twitter:image`,
              content: imageUrl
            },
            {
              name: `metakeywords`,
              content: name
            },
            {
              name: `og:url`,
              content: `${SOLE_PROJECT_LINK}/${data.allAvettiCategory.nodes[0].url}`
            }
          ]}
        />
      }
      {data.allAvettiCategory.nodes[0].name === "Sellers" ? null : (
        <CategoryPage
          data={data.allAvettiCategory.nodes[0]}
          pageContext={pageContext}
          navCategory={navCategory}
          supplier={[]}
        />
      )}
    </Layout>
  );
};

export default Category;
